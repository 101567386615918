if ($('body').is('#groups')) {
    scope = document.querySelector('dom-bind');

    scope.getRepeatedHtmlArray = function(count){
        return Array(count);
    };

    // redirect to the url of the group when we click on the image or the button
    scope.viewGroup = function (e) {
        let selectedItem = e.detail.item;
        let renderedGroups = JSON.parse(document.getElementById('rendered_group').value);
        let selectedGroup = renderedGroups.find(group => group.id === selectedItem.id);

        window.location.href = selectedGroup.url;
    };

    scope.handleFilter = function (e) {
        if (renderedGroups.length === 0) {
            return;
        }

        this.groups = renderedGroups.filter(group => group.title.toLowerCase().search(e.target.value.toLowerCase()) > -1);
    }
}